@font-face {
  font-family: 'Open Sans Regular';
  src: url('../../fonts/OpenSans-Regular.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Medium';
  src: url('../../fonts/OpenSans-Medium.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('../../fonts/OpenSans-SemiBold.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans ExtraBold';
  src: url('../../fonts/OpenSans-ExtraBold.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'Alice Regular';
  src: url('../../fonts/Alice-Regular.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa Regular';
  src: url('../../fonts/Comfortaa-Regular.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa Light';
  src: url('../../fonts/Comfortaa-Light.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa Bold';
  src: url('../../fonts/Comfortaa-Bold.ttf');
  font-style: normal;
}