:root {
  --primaryTextColor: #F6F6F6;
  --secondaryTextColor: #D4CEBF;
  --backgroundColor: #231f20;
  --hoverColor: #5E4715;

  --primaryTextColorDark: #EAE6DE;
  --backgroundColorDark: #22211E;
  --hoverColorDark: #866929;
  --lightGrayDark: #716F6B;
  --secondaryTextColorDark: #676663;
  --lightGoldDark: #B4A075;
  --cardBlackDark: #2B2925;

  --transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
