@import 'modern-normalize/modern-normalize.css';

h1,
h2,
h3,
p {
  margin: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

main, header, footer {
  color: var(--primaryTextColor);
  background-color: var(--backgroundColorDark);
}

main {
  padding-top: 75px;
}